@import './styles/variables/colors/colors';

*{
	margin: 0;
	padding: 0;

	body{
		width: 100vw;
		overflow: hidden;
		background-color: $background;
	}
}
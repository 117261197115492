@import '../../styles/variables/colors/colors';
@import '../../styles/variables/mixins/font-style';
@import 'include-media';

.messageWrapper{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .responsiveMessage{
    @include subtitle_1;
    color: $black;
    text-align: center;
  }
}

.home{
  width: 100vw;
  height: calc(100vh - 85px);
  background: $white;
  display: flex;
  @include media(">=tablet", "<1500px") {
    height: calc(100vh - 75px);
  }
  @include media("<tablet") {
    height: calc(100vh - 70px);
  }

  .home__left-column{
    height: 100%;
    width: 15%;
    @include media(">=desktop", "<1500px") {
      width: 7%;
    }
    @include media("<desktop") {
      display: none;
    }

    .home__left__square{
      width: 100%;
      height: 35%;
    }

    .home__left__square:first-child{
      height: 50%;
      border-bottom: 1px solid $black;
    }

    .home__left__square:last-child{
      height: 15%;
      border-top: 1px solid $black;
    }
  }

  .home__middle-column{
    height: 100%;
    width: 70%;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    overflow-x: scroll;
    @include media(">=desktop", "<1500px") {
      width: 86%;
    }
    @include media("<desktop") {
      width: 100%;
    }

    .home__project:last-child{
      @include media("<tablet") {
        margin-bottom: 150px;
      }
    }

    .home__project{
      width: 100%;
      height: 600px;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      box-sizing: border-box;
      margin: 5vh 0;
      display: flex;
      @include media(">=tablet", "<1500px") {
        height: 500px;
      }
      @include media("<tablet") {
        flex-direction: column-reverse;
        height: 800px;
        border: 1px solid $black;
      }

      .home__project-left{
        width: 40%;
        height: 100%;
        border-right: 1px solid $black;
        padding: 4%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @include media(">=tablet", "<1200px") {
          width: 50%;
        }
        @include media("<tablet") {
          width: 100%;
          height: 60%;
          border-right: 0px;
          border-top: 1px solid $black;
          padding: 0% 5%;
        }

        .home__project-left__title{
          @include title_2;
        }

        .home__project-left__description{
          @include body;
        }

        .home__project-left__subtitle{
          @include subtitle_1;
        }

        .home__project-left__technologies{
          display: flex;
          align-items: center;
          @include media("<tablet") {
            overflow: hidden;
          }

          .home__project-left__technologie{
            @include small;
            width: 80px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $red;
            margin-right: 12px;
            border: 1px solid $red;
            border-radius: 16px;
            @include media("<tablet") {
              margin-right: 6px;
            }
          }
          .home__project-left__technologie:last-child{
            margin-right: 0;
          }
        }

        .home__project-left__button{
          width: 100%;
          height: 15%;
          background-color: $black;
          color: $white;
          cursor: pointer;
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          @include body;
        }
      }

      .home__project-right{
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media(">=tablet", "<1200px") {
          width: 50%;
        }
        @include media("<tablet") {
          width: 100%;
          height: 40%;
        }

        .home__project-left__img{
          width: 90%;
          @include media("<tablet") {
            width: auto;
            max-width: 95%;
            height: auto;
            max-height: 95%;
          }
        }
      }
    }

  }

  .home__right-column{
    height: 100%;
    width: 15%;
    @include media(">=desktop", "<1500px") {
      width: 7%;
    }
    @include media("<desktop") {
      display: none;
    }

    .home__right__square{
      width: 100%;
      height: 38%;
    }

    .home__right__square:first-child{
      height: 22%;
      border-bottom: 1px solid $black;
    }

    .home__right__square:nth-child(2){
      height: 20%;
      border-bottom: 1px solid $black;
    }

    .home__right__square:last-child{
      height: 20%;
      border-top: 1px solid $black;
    }
  }

}
@import '../../styles/variables/colors/colors';
@import '../../styles/variables/mixins/font-style';
@import 'include-media';


.aboutMobile{
  width: 100vw;
  height: calc(100vh - 70px);
  background-color: $black;

  .aboutMobile__presentation-wrapper{
    @include body;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    color: $white;
    box-sizing: border-box;
    padding: 0% 8%;
  }
  
  .aboutMobile__photo-wrapper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;

    .aboutMobile__photo{
      width: 80%;
      max-width: 300px;
      margin-top: 10%;
    }
  }

  .aboutMobile__socials{
    width: 100%;
    height: 180px;
    background-color: $black;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 3% 0%;

    .aboutMobile__socials-line{
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .aboutMobile__socials-link{
        @include body;
        color: $white;
        text-decoration: underline;
      }
    }
  }
  .aboutMobile__resume-wrapper{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $black;
    background-color: $white;

    .aboutMobile__resume{
      @include body;
      width: 40%;
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background-color: $black;
      color: $white;
    }
  }
}

.about{
  width: 100vw;
  height: 93vh;
  background: $white;
  @include media("<900px") {
    height: 88vh
  }
  

  .about__top{
    width: 100%;
    height: 50%;
    border-bottom: 1px solid $black;
    display: flex;

    .about__top__firstSection{
      width: 38%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      @include media(">=desktop", "<1300px") {
        width: 45%;
      }
      @include media(">=900px", "<desktop") {
        width: 50%;
      }
      @include media("<900px") {
        width: 60%;
        justify-content: center;
      }

      .top__firstSection-wrapper{
        width: 70%;
        height: 100%;
        border-right: 1px solid $black;
        border-left: 1px solid $black;
        @include media("<900px") {
          width: 80%;
          justify-content: center;
        }

        .firstSection-left-presentation{
          @include body;
          width: 100%;
          height: 210px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $black;
          color: $white;
          box-sizing: border-box;
          padding: 8%;
          margin-top: 14%;
        }
      }

    }

    .about__top__secondSection{
      display: flex;
      flex-direction: column;
      width: 42%;
      height: 100%;
      @include media(">=desktop", "<1300px") {
        width: 35%;
      }
      @include media(">=900px", "<desktop") {
        width: 30%;
      }
      @include media("<900px") {
        display: none;
      }

      .top__secondSection-firstLine{
        width: 100%;
        height: 52%;
        border-bottom: 1px solid $black;

        .placeholderDiv{
          width: 65%;
          height: 100%;
          border-right: 1px solid $black;
        }
      }

      .top__secondSection-secondLine{
        width: 100%;
        height: 48%;

        .placeholderDiv{
          width: 45%;
          height: 100%;
          border-right: 1px solid $black;
        }
      }
    }

    .about__top__thirdSection{
      display: flex;
      width: 20%;
      height: 100%;
      overflow: hidden;
      background-image: url("../../assets/images/about/new-york.jpeg");
      background-size: cover;
      @include media("<900px") {
        width: 40%;
      }
    }

  }

  .about__bottom{
    width: 100%;
    height: 50%;
    display: flex;

    .about__bottom__firstSection{
      width: 38%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      @include media("<900px") {
        width: 40%
      }

      .bottom__firstSection-wrapper{
        width: 100%;
        height: 35%;
        border-top: 1px solid $black;
        display: flex;
        justify-content: flex-end;
        @include media(">=desktop", "<1300px") {
          height: 40%;
        }
        @include media("<desktop") {
          height: 45%;
        }
        

        .bottom__firstSection__socials{
          width: 60%;
          height: 100%;
          background-color: $black;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 3% 0%;
          @include media("<900px") {
            width: 100%
          }

          .bottom__firstSection__socials-Line{
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .bottom__firstSection__socials-link{
              @include body;
              color: $white;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .about__bottom__secondSection{
      width: 42%;
      height: 100%;
      @include media("<900px") {
        width: 30%
      }

      .bottom__secondSection__wrapper{
        width: 45%;
        height: 100%;
        border-right: 1px solid $black;
        @include media("<900px") {
          width: 100%;
          height: 55%;
        }

        .bottom__secondSection__imgWrapper{
          width: 100%;
          height: 65%;
          overflow: hidden;
          background-image: url("../../assets/images/about/baptiste.jpg");
          background-size: cover;
          @include media(">=desktop", "<1300px") {
            height: 60%;
          }
          @include media(">=900px", "<desktop") {
            height: 55%;
          }
          @include media("<900px") {
            height: 100%;
          }
        }
      }
    }

    .about__bottom__thirdSection{
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid $black;
      @include media("<900px") {
        width: 30%
      }

      .bottom__thirdSection__wrapper{
        width: 100%;
        height: 25%;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        @include media("<900px") {
          display: flex;
          justify-content: flex-end;
        }

        .bottom__thirdSection__linkWrapper{
          height: 100%;
          width: 50%;
          background-color: $black;
          display: flex;
          justify-content: center;
          align-items: center;

          .bottom__thirdSection__resume{
            @include body;
            color: $white;
          }
        }
      }
    }

  }

}
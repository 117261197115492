@mixin title_1 {
	font-family: soleil, sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 32px;
}

@mixin title_2 {
	font-family: soleil, sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 24px; 
}

@mixin subtitle_1 {
	font-family: soleil, sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 16px; 
}

@mixin body {
	font-family: soleil, sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
}

@mixin small {
	font-family: soleil, sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 12px; 
}
